import Vue from "vue";
import svg4everybody from "svg4everybody";

import polyfill from "@/polyfills";
import "@/assets/styles/app.postcss";
import "@/lib/svg-sprite";
import initModules from "@/lib/init-modules";

Vue.config.productionTip = false;

const setJsDocumentClasses = () => {
  const docEl = document.documentElement;

  docEl.classList.remove("js-loading");
  docEl.classList.add("js-loaded");
};
/* eslint-disable no-param-reassign */
const init = (APP, availableModules) => {
  APP.debug = APP.debug || !process.env.NODE_ENV === "production";

  APP.modules = initModules(APP.modules, availableModules, APP.debug);
};

window.addEventListener("DOMContentLoaded", () => {
  setJsDocumentClasses();
  svg4everybody();

  polyfill().then(() => {
    init(window.APP, {});
  });
});
