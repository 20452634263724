// import objectFitImages from "object-fit-images";
// import arrayPrototypeFindIndex from "jspolyfill-array.prototype.findIndex";
// import arrayPrototypeFind from "./Array.prototype.find";

export default () =>
  new Promise((resolve) => {
    // arrayPrototypeFind();
    // objectFitImages();
    resolve();
  });
